$blue: #3d2b68;

$red: #88283c;
//$black: #212529;
$dark: #303030;
$secondary: #424242;
$green: #3f8686;
$form-select-bg: $secondary;
$enable-negative-margins: true;
$btn-focus-box-shadow: none;
//

$form-switch-color: #fff;
$form-check-input-border: 1px solid #5f6266;

$input-border-color: #5f6266;
// $nav-tabs-link-active-border-color: $input-border-color $input-border-color $dark;
//$nav-tabs-link-active-bg: $dark ;
$nav-tabs-link-hover-border-color: $input-border-color ;



//
$border-color: $input-border-color;

$input-bg: #3a3a3a;
$input-disabled-bg: #3a3a3a;
//
$body-bg: #303030;
$body-color: #dbdbdb;
$white: #dbdbdb;
$primary: #de5b76;

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2rem,
);
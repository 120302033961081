//**Order of imports is important**
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";
// 2. Include any default variable overrides here
@import "_custom-variables.scss";
// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
// 4. Include any default map overrides here
//--
// 5. Include remainder of required parts
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/utilities";
//
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
//@import "../../node_modules/bootstrap/scss/vendor/rfs";
@import "../../node_modules/bootstrap/scss/helpers/position";
@import "../../node_modules/bootstrap/scss/helpers/color-bg";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "_utilities.scss";
// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";

//@import "../../node_modules/bootstrap/scss/bootstrap.scss";
html,
body,
#root {
  height: 100% !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

//bootstrap overrides
.theme-sidebar-container {
  .nav-link.active {
    background-image: linear-gradient(113deg, #de5b76 0%, #bf546a 100%);
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #f6f6f6;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
  opacity: 0.8;
}

:disabled,
.theme-disabled {
  cursor: not-allowed;
}

.table th {
  &:first-child {
    padding-left: 1rem !important;
  }

  &:last-child {
    padding-right: 1rem !important;
  }
}

//
.theme-container {
  background-color: #212529;
}

.theme-main-container {
  margin-left: 260px;
}

.theme-sidebar-container {
  width: 260px;
  position: fixed;
  bottom: 0;
  top: 0;
  padding-top: 70px;
  background: #303030;
}

.theme-logo {
  letter-spacing: -1px;
  margin-bottom: 18px;
  font-weight: bold;
  line-height: 100%;

  .theme-ef-logo {
    font-size: 20px;
    font-weight: 400;
    color: #7b7b7b;
    letter-spacing: -0.9px;
  }
}

.theme-loading-overlay {
  overflow-y: overlay !important;
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #3030305b;

  >div {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.theme-account-user-name {
  position: fixed;
  margin-top: -43px;
}

.theme-inline-title {
  position: absolute;
  margin-top: -28px;
}

.theme-overflow {
  @include media-breakpoint-up(xl) {
    overflow-y: overlay !important;
    bottom: 0;
    left: 0;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  &.theme-overflow-table::-webkit-scrollbar-track {
    margin-top: 40px;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: $primary transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
  background-clip: padding-box;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 10px;
  background-clip: padding-box;
  border: 4px solid rgba(0, 0, 0, 0);
}

.theme-pointer {
  cursor: pointer;
}

.theme-failure-bar-error-types {
  max-width: 180px !important;
}

.theme-failure-bar-id-types {
  max-width: 100px !important;
}

.theme-failure-bar-limit {
  max-width: 84px !important;
}

.theme-hover-row {
  button {
    line-height: initial;
    visibility: hidden;
  }

  &:hover {
    button {
      visibility: visible;
      line-height: initial;
    }
  }
}

.theme-input {
  &::after {
    content: "";
    background-color: #bf546a;
    background-image: url(../img/search.svg);
    height: 28px;
    background-size: 28px 28px;
    width: 28px;
  }
}

.theme-me--100 {
  margin-right: -100%;
}

.card-header-tabs .nav-link.active {
  background-color: $dark;
}

.nav-tabs .nav-link.active {
  border-color: $input-border-color $input-border-color $dark;
}

.theme-py-1 {
  padding-top: 0.15rem !important;
  padding-bottom: 0.15rem !important;
}

.theme-display-card {
  .theme-display-card-title {
    color: #cda0a0;
  }
}

.theme-users-search-types {
  max-width: 130px !important;
}

.theme-white-space {
  white-space: pre-wrap;
}

.theme-visually-hidden {
  visibility: hidden;
}

.theme-live {
  letter-spacing: initial;
  transform: rotate(353deg);
  padding-top: 4px;
  padding-bottom: 5px;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;

  >svg {
    pointer-events: all;
    cursor: not-allowed;
  }
}

input {
  color-scheme: dark;
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23000000" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.theme-nav {
  .nav-link:hover {
    background-color: var(--bs-secondary);
  }
}

.theme-outline-active {
  outline: #de5b76 solid 2px;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 9px 4px #de5b76a6;
}

.theme-btn-hover {
  &:hover {
    background-color: #de5b76;
    color: #fff;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #de5b76 transparent;
}

table {
  overflow: hidden;
}

tr:hover {
  background-color: rgb(209 47 131 / 6%);
  ;
}

td,
th {
  position: relative;
}

td:hover::after,
th:hover::after {
  content: "";
  position: absolute;
  background-color: rgb(209 47 131 / 6%);
  left: 0;
  top: -5000px;
  height: 10000px;
  width: 100%;
  z-index: -2;
  pointer-events: none;
}

// parent needs to be flex
.theme-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: inherit;
}

//overide for injected html tables
table {
  tbody {
    tr {
      background-color: inherit !important;
    }
  }
}